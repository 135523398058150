<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouse_report.warehouseWiseFarmerInformationReport') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('warehouse_service.farmer_id')"
                    label-for="farmer_id"
                    >
                    <b-form-input
                        id="farmer_id"
                        v-model="search.farmer_id"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
              <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('org_pro_division.division')"
                    label-for="division_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('warehouse_report.division')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="division_id"
                    :options="divisionList"
                    id="division_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
              <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                  plain
                  v-model="search.region_id"
                  :options="regionList"
                  id="division_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
              <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('warehouse_report.district')"
                    label-for="district_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('warehouse_report.district')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                    plain
                    v-model="search.district_id"
                    :options="regionDistrictLists"
                    id="district_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
              <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('warehouse_report.upazilla')"
                    label-for="upazilla_id"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('warehouse_report.upazilla')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                      plain
                      v-model="search.upazila_id"
                      :options="upazilaList"
                      id="upazilla_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    :label="$t('globalTrans.union')"
                    label-for="union_id"
                >
                <b-form-select
                    plain
                    v-model="search.union_id"
                    :options="unionList"
                    id="union_id"
                >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                <ValidationProvider name="Warehouse Type" vid='warehouse_type_id' rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    label-for="warehouse_type_id"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{ $t('warehouse_config.warehouse_type') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="search.warehouse_type_id"
                        :options="warehouseTypeList"
                        id="warehouse_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div  class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Warehouse Name" vid='warehouse_id' rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-cols-md="4"
                    label-for="warehouse_id"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                        {{ $t('warehouse_report.warehouse_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Farmer Name" vid='farmer_id' rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="farmer_name"
                slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{ $t('warehouse_service.farmer_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="search.farmer_id"
                    :options="farmerList"
                    id="farmer_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="''" >{{ farmerLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12">
                <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
        </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.warehouseWiseFarmerInformationReport') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="headerInfo"
                  worksheet="Warehouse Wise Farmer’s Information Report Sheet"
                  name="warehouse-wise-farmer-information-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
                <!-- <export-excel
                    class="btn btn-danger"
                    :data="warehouseWiseReport"
                    worksheet="Warehouse Wise Farmer’s Information Report Sheet"
                    type="csv"
                    name="filename.xls">
                    {{ $t('globalTrans.export_csv') }}
                </export-excel> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="search.org_id" :loading-state="loadingState">
                          {{ $t('warehouse_report.warehouseWiseFarmerInformationReport') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.division') }}: </strong> {{ getDivisionName(search.division_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.region') }}: </strong> {{ getRegionName(search.region_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.district') }}: </strong> {{ getDistrictName(search.district_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.upazila') }}: </strong> {{ getUpazilaName(search.upazilla_id) }}
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <strong>{{ $t('globalTrans.union') }}: </strong> {{ getUnionName(search.union_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_config.warehouse_type') }}: </strong> {{ getWarehouseTypeName(search.warehouse_type_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_information.warehouse_name') }}: </strong> {{ getWarehouseNameCustom(search.warehouse_id) }}
                </b-col>
                <b-col md="3">
                  <strong>{{ $t('warehouse_service.farmer_name') }}: </strong> {{ getFarmerName(search.farmer_id) }}
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.date') }}</b-th>
                            <b-td>{{ new Date().toLocaleDateString("en-US") | dateFormat }}</b-td>
                            <b-td></b-td><b-td></b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('warehouse_report.warehouse_name') }}</b-th>
                            <b-td v-if="farmerDetail.warehouse_id">{{ getWarehouseName(farmerDetail.warehouse_id, 'ListData') }}</b-td>
                            <b-th>{{ $t('warehouse_report.farmer_name') }}</b-th>
                            <b-td>{{ $i18n.locale === 'bn' ? farmerDetail.name_bn : farmerDetail.name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('warehouse_report.father_name') }}</b-th>
                            <b-td>{{ $i18n.locale === 'bn' ? farmerDetail.father_name_bn : farmerDetail.father_name }}</b-td>
                            <b-th>{{ $t('warehouse_report.mobile_number') }}</b-th>
                            <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(farmerDetail.username, { useGrouping: false }) }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('warehouse_report.address') }}</b-th>
                            <b-td>{{ $i18n.locale === 'bn' ? farmerDetail.address_bn : farmerDetail.address }}</b-td>
                            <b-th>{{ $t('warehouse_report.land_area') }}</b-th>
                            <b-td>{{ $n(farmerDetail.land_area) }}</b-td>
                          </b-tr>
                        </b-thead>
                      </b-table-simple>
                      <b-table-simple hover small caption-top responsive aria-hidden="loading | listReload ? 'true' : null">
                        <caption class="text-center" style="font-weight:bold; text-color:white">{{ $t('warehouse_report.warehouseWiseFarmerInformationReport') }}</caption>
                        <b-thead>
                          <b-tr>
                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th>{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th>{{ $t('warehouse_report.total_stock') }}</b-th>
                            <b-th>{{ $t('warehouse_report.release_stock') }}</b-th>
                            <b-th>{{ $t('warehouse_report.remaining_stock') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <!-- <b-tr>
                              <b-td :rowspan="info.total">{{ index+1 }}</b-td>
                              <b-th :rowspan="info.total"><span class="capitalize">{{ info.crop_type_name }}</span></b-th>
                          </b-tr> -->
                              <b-tr v-for="(info, index) in listData" :key="index">
                                <b-td>{{ index + 1 }}</b-td>
                                <b-td class="capitalize">{{ $i18n.locale === 'bn' ? info.crop_type_name_bn : info.crop_type_name }}</b-td>
                                <b-td class="capitalize">{{ $i18n.locale === 'bn' ? info.crop_name_bn : info.crop_name }}</b-td>
                                <b-td>{{ $n(info.total_stock) }}</b-td>
                                <b-td>{{ $n(info.release_stock) }}</b-td>
                                <b-td>{{ $n(info.remaining_stock) }}</b-td>
                              </b-tr>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr>
                            <b-td colspan="3" variant="secondary" class="text-left">
                              {{ $i18n.locale === 'en' ? 'Gross Total :' : 'সর্ব মোট :' }}<b></b>
                            </b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalStock) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalRelease) }}</b-td>
                            <b-td variant="secondary" class="text-left">{{ $n(totalRemaining) }}</b-td>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { warehouseWiseFarmerReport } from '../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import { dateFormat } from 'highcharts'
Vue.use(excel)

const excelColumn = {
  'Crop Type Name': 'cropTypeName',
  'Crop Name': 'cropName',
  'Total Stock(Quintal)': 'totalStock',
  'Stock Release(Quintal)': 'stockRelease',
  'Remaining Stock(Quintal)': 'remainingStock'
}

const excelColumnBn = {
  'ফসলের ধরনের নাম': 'cropTypeName',
  'ফসলের নাম': 'cropName',
  'মোট মজুত(কুইন্টাল)': 'totalStock',
  'মজুত অবমুক্তি(কুইন্টাল)': 'stockRelease',
  'অবশিষ্ট মুজত(কুইন্টাল)': 'remainingStock'
}

export default {
  mixins: [ModalBaseMasterList],
  components: {
      ListReportHead,
      ValidationObserver,
      ValidationProvider
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      division_id: 0,
      region_id: 0,
      totalStock: '',
      totalRelease: '',
      totalRemaining: '',
      search: {
        farmer_id: '',
        farmerListId: 0,
        org_id: 13,
        region_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        warehouse_id: 0,
        warehouse_type_id: 0,
        fiscal_year_id: 0
      },
      farmerDetail: '',
      warehouseWiseFarmerReport: [],
      warehouseWiseReport: [],
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      farmerList: [],
      tempList: [],
      warehouseList: this.$store.state.warehouse.warehouseInfoList,
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      districtLoading: false,
      farmerLoading: false
    }
  },
  computed: {
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
        const footerData = this.getFooter()
        const list = this.listData
        var newDataxl = list.concat(footerData)
        const customizeData = newDataxl.map(item => {
            if (this.$i18n.locale === 'bn') {
                item.cropTypeName = item.crop_type_name_bn
                item.cropName = item.crop_name_bn
                item.totalStock = this.$n(item.total_stock)
                item.stockRelease = this.$n(item.release_stock)
                item.remainingStock = this.$n(item.remaining_stock)
            } else {
                item.cropTypeName = item.crop_type_name
                item.cropName = item.crop_name
                item.totalStock = this.$n(item.total_stock)
                item.stockRelease = this.$n(item.release_stock)
                item.remainingStock = this.$n(item.remaining_stock)
            }
            return Object.assign({}, item)
        })
        return customizeData
    },
    headerInfo () {
      const firstRow = this.$t('globalTrans.division') + ' : ' + this.getDivisionName(this.search.division_id) + ' ' + this.$t('warehouse_config.region') + ' : ' + this.getRegionName(this.search.region_id) + ' ' + this.$t('globalTrans.district') + ' : ' + this.getDistrictName(this.search.district_id) + ' ' + this.$t('globalTrans.upazila') + ' : ' + this.getUpazilaName(this.search.upazilla_id)
      const secondRow = this.$t('globalTrans.union') + ' : ' + this.getUnionName(this.search.union_id) + ' ' + this.$t('warehouse_config.warehouse_type') + ' : ' + this.getWarehouseTypeName(this.search.warehouse_type_id) + ' ' + this.$t('warehouse_information.warehouse_name') + ' : ' + this.getWarehouseNameCustom(this.search.warehouse_id) + ' ' + this.$t('warehouse_service.farmer_name') + ' : ' + this.getFarmerName(this.search.farmer_id)
      return [this.$t('warehouse_report.warehouseWiseFarmerInformationReport'), '', firstRow, secondRow, '']
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionList: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    fiscalYearList () {
        return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    },
    warehouseTypeList () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  mounted () {
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
        this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazila_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    },
    'search.warehouse_id': async function (newVal, oldVal) {
        await this.getFarmerList(newVal)
    }
  },
  methods: {
    resetData () {
      this.totalStock = ''
      this.totalRelease = ''
      this.totalRemaining = ''
      this.farmerDetail = ''
      this.$store.dispatch('setList', [])
      this.warehouseWiseReport = []
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(warehouseServiceBaseUrl, warehouseWiseFarmerReport, this.search).then(response => {
        if (response.success) {
          if (response.data.length > 0) {
            this.tableShow = true
            this.$store.dispatch('setList', this.dataList(response))
            // this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          } else {
            this.resetData()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          }
        } else {
          this.getFarmerData(this.search.farmer_id)
          this.resetData()
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      })
    },
    async getFarmerData (farmerId) {
        const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-profile/' + farmerId)
        if (response.data) {
            this.farmerDetail = response.data
        }
    },
    dataList (data) {
      if (data.data) {
        // const id = data.farmer_id
        // RestApi.getData(authServiceBaseUrl, '/user/farmer-profile/' + id).then(response => {
        //   if (response) {
        //     this.farmerDetail = response.data
        //   }
        // })
          this.getFarmerData(this.search.farmer_id)
      }
      var tS = 0
      var tRel = 0
      var tRem = 0
      var myArray = data.data
      var groups = {}
      for (var i = 0; i < myArray.length; i++) {
        var cropType = myArray[i].crop_type_id
        if (!groups[cropType]) {
            groups[cropType] = []
        }
        var newData = {
          crop_id: myArray[i].crop_id,
          release_stock: myArray[i].release_stock,
          remaining_stock: myArray[i].remaining_stock,
          total_stock: myArray[i].total_stock
        }
        groups[cropType].push(newData)
      }
      myArray = []
      for (var Name in groups) {
        myArray.push({ crop_type_id: Name, data: groups[Name] })
      }
      const newarr = myArray
      var customarray = newarr
      customarray.forEach(item => {
        item.data.forEach((listItem, index) => {
          if (index === 0) {
              listItem.crop_type_id = item.crop_type_id
          } else {
               listItem.crop_type_id = null
          }
        })
      })
      const pdf = []
      customarray.forEach((item) => {
        item.data.forEach((list) => {
          pdf.push(list)
          tS = tS + list.total_stock
          tRel = tRel + list.release_stock
          tRem = tRem + list.remaining_stock
        })
      })
      this.totalStock = tS
      this.totalRelease = tRel
      this.totalRemaining = tRem
      const CustomData = pdf
      const warehouseTypeList = this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
      const commodityNameList = this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
      const listData = CustomData.map(item => {
        const warehouseTypeObject = warehouseTypeList.find(warehouseType => warehouseType.value === parseInt(item.crop_type_id))
        const commodityNameObject = commodityNameList.find(commodityName => commodityName.value === item.crop_id)
        var warehouseTypeData = {
            crop_type_name: null,
            crop_type_name_bn: null
            }
        if (warehouseTypeObject) {
          warehouseTypeData = {
            crop_type_name: warehouseTypeObject !== undefined ? warehouseTypeObject.text_en : '',
            crop_type_name_bn: warehouseTypeObject !== undefined ? warehouseTypeObject.text_bn : ''
          }
        } else {
          warehouseTypeData = {
            crop_type_name: null,
            crop_type_name_bn: null
          }
        }
        var commodityNameData = {
          crop_name: null,
          crop_name_bn: null
        }
        if (commodityNameObject) {
          commodityNameData = {
            crop_name: commodityNameObject !== undefined ? commodityNameObject.text_en : '',
            crop_name_bn: commodityNameObject !== undefined ? commodityNameObject.text_bn : ''
          }
        }
        return Object.assign({}, item, warehouseTypeData, commodityNameData)
      })
      this.warehouseWiseReport = listData
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      return listData
    },
    getFooter () {
        const footer = [{
            cropName: null,
            cropTypeName: null,
            crop_id: null,
            crop_name: null,
            crop_name_bn: null,
            crop_type_id: this.$t('sitePreference.grossTotal'),
            crop_type_name: this.$t('sitePreference.grossTotal'),
            crop_type_name_bn: this.$t('sitePreference.grossTotal'),
            release_stock: this.totalRelease,
            remainingStock: this.totalRemaining,
            remaining_stock: this.totalRemaining,
            stockRelease: this.totalRelease,
            totalStock: this.totalStock,
            total_stock: this.totalStock
            }]
        return footer
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const head = this.getHeader()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.total_stock'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.release_stock'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.remaining_stock'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]

      rowData.unshift(labels)
      const finalRow = head.concat(rowData)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Wise Farmer’s Information Report' : 'গুদাম অনুযায়ী কৃষকের তথ্য প্রতিবেদন'
      // const columnWids = ['5%', '7%', '7%', '5%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '7%', '5%', '5%', '5%', '5%']
      const header1 = [
        { text: `${this.$t('globalTrans.division')} : ${this.getDivisionName(this.search.division_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.region')} : ${this.getRegionName(this.search.region_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.district')} : ${this.getDistrictName(this.search.district_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('globalTrans.upazila')} : ${this.getUpazilaName(this.search.upazilla_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const header2 = [
        { text: `${this.$t('globalTrans.union')} : ${this.getUnionName(this.search.union_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_config.warehouse_type')} : ${this.getWarehouseTypeName(this.search.warehouse_type_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_information.warehouse_name')} : ${this.getWarehouseNameCustom(this.search.warehouse_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: `${this.$t('warehouse_service.farmer_name')} : ${this.getFarmerName(this.search.farmer_id)}`, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      const extraData = {
        totalHeaderRows: 1,
        subHead: [header1, header2]
      }
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, finalRow, '*', extraData, 'warehouse-wise-farmer-information-report')
    },
    getHeader () {
        const date = new Date().toLocaleDateString('en-US')
        var header = [
            [{ text: this.$t('globalTrans.date') }, { text: this.$n(date.split('/')[0], { useGrouping: false }) + '/' + this.$n(date.split('/')[1], { useGrouping: false }) + '/' + this.$n(date.split('/')[2], { useGrouping: false }) }, { }, { }, { }, { }],
            [{ text: this.$t('warehouse_report.warehouse_name') }, { text: this.$i18n.locale === 'en' ? this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text_en : this.$store.state.warehouse.warehouseInfoList.find(document => document.value === this.farmerDetail.warehouse_id).text_bn },
            { }, { text: this.$t('warehouse_report.farmer_name') }, { text: this.$i18n.locale === 'bn' ? this.farmerDetail.name_bn : this.farmerDetail.name }, { }],
            [{ text: this.$t('warehouse_report.father_name') }, { text: this.$i18n.locale === 'bn' ? this.farmerDetail.father_name_bn : this.farmerDetail.father_name }, { },
            { text: this.$t('warehouse_report.mobile_number') }, { text: this.$i18n.locale === 'bn' ? '০' + this.$n(this.farmerDetail.username, { useGrouping: false }) : '0' + this.$n(this.farmerDetail.username, { useGrouping: false }) }, { }],
            [{ text: this.$t('warehouse_report.address') }, { text: this.$i18n.locale === 'bn' ? this.farmerDetail.address_bn : this.farmerDetail.address }, { },
            { text: this.$t('warehouse_report.land_area') }, { text: this.$n(this.farmerDetail.land_area) }, { }]
        ]
        return header
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'total_stock' },
          { key: 'release_stock' },
          { key: 'remaining_stock' }
        ]
      var serial = 0
      var listData = this.warehouseWiseReport.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'crop_type_name') {
                return { text: item.crop_type_name }
            }
            if (keyItem.key === 'crop_type_name_bn') {
                return { text: item.crop_type_name_bn }
            }
            if (keyItem.key === 'crop_name') {
                return { text: item.crop_name }
            }
            if (keyItem.key === 'crop_name_bn') {
                return { text: item.crop_name_bn }
            }
            if (keyItem.key === 'total_stock') {
                return { text: this.$n(item.total_stock) }
            }
            if (keyItem.key === 'release_stock') {
                return { text: this.$n(item.release_stock) }
            }
            if (keyItem.key === 'remaining_stock') {
                return { text: this.$n(item.remaining_stock) }
            }
          return { text: item[keyItem.key] }
        })
        return rowData
      })
      const total = [
            this.$i18n.locale === 'en' ? { text: 'GrossTotal :' } : { text: 'সর্ব মোট :' },
            { text: '' },
            { text: '' },
            { text: this.$n(this.totalStock) },
            { text: this.$n(this.totalRelease) },
            { text: this.$n(this.totalRemaining) }
      ]
      listData.push(total)
      return listData
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObjectTemp = response.filter(document => document.region_id === regionId)
                const dataObject = dataObjectTemp.filter(item => item.status === 0)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                    } else {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                    }
                })
            }
        })
      this.districtLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
     getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      if (Id && type === 'ListData') {
        list = warehouseList.filter(warehouse => warehouse.value === Id)
        return list[0].text
      }
      return list
    },
    async getFarmerList (warehouseId = null) {
        this.farmerLoading = true
        await RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
            if (response) {
                var list = response.data.filter(document => document.warehouse_id === warehouseId)
            }
            const flist = list.map(obj => {
                // if (this.$i18n.locale === 'bn') {
                //     return { value: obj.username, text: obj.name_bn }
                // } else {
                //     return { value: obj.username, text: obj.name }
                // }
                const customItem = {
                  value: obj.username,
                  text: this.$i18n.locale === 'bn' ? obj.name_bn : obj.name,
                  text_en: obj.name,
                  text_bn: obj.name_bn
                }
                return Object.assign({}, obj, customItem)
            })
            this.farmerList = flist
            return flist
        })
        this.farmerLoading = false
    },
    getDivisionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getRegionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.regionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getDistrictName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUpazilaName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getUnionName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.commonObj.unionList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseTypeName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseTypeList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getWarehouseNameCustom (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.$store.state.warehouse.warehouseInfoList.find(item => item.value === parseInt(id))
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    },
    getFarmerName (id) {
      let nameEn = this.$t('globalTrans.all', 'en')
      let nameBn = this.$t('globalTrans.all', 'bn')
      if (id) {
        const obj = this.farmerList.find(item => item.value === id)
        nameEn = obj?.text_en
        nameBn = obj?.text_bn
      }
      return this.currentLocale === 'en' ? nameEn : nameBn
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
